import React from "react"
import { StaticQuery, graphql } from "gatsby"

import CardPickup from "components/CardPickup"

const PickupTags = () => (
  <StaticQuery
    query={pickupTagsQuery}
    render={data => {
      return (
        <div className="card is-separate is-overflow-hidden">
          <div className="card-header">
            <div className="inner">
              <h2 className="heading is-dark-2 is-strong is-sm">Pickup Tags</h2>
            </div>
          </div>
          <ul className="card-list is-separate">
            {data.tags.edges.map((edge, i) => (
              <li key={i}>
                <CardPickup
                  to={`/tags/${edge.node.data.slug}/`}
                  title={edge.node.data.name}
                  icon_style={edge.node.data.icon_style}
                  icon_name={edge.node.data.icon_name}
                  icon_color={edge.node.data.icon_color}
                  content={
                    edge.node.data.content.childMarkdownRemark.rawMarkdownBody
                  }
                />
              </li>
            ))}
          </ul>
        </div>
      )
    }}
  />
)

export default PickupTags

const pickupTagsQuery = graphql`
  query PickupTagsQuery {
    tags: allAirtable(
      filter: {
        table: { eq: "tags" }
        data: { published: { eq: true }, pickup: { eq: true } }
      }
    ) {
      edges {
        node {
          data {
            name
            published
            pickup
            slug
            icon_style
            icon_name
            icon_color
            content {
              childMarkdownRemark {
                rawMarkdownBody
                excerpt(pruneLength: 50, truncate: true)
              }
            }
            posts {
              data {
                published
              }
            }
          }
        }
      }
    }
  }
`
