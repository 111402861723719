import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import Head from "components/Head"
import GridAside from "components/GridAside"
import PickupTags from "components/widgets/PickupTags"
import LastUpdated from "components/widgets/LastUpdated"
import FirstPublished from "components/widgets/FirstPublished"
import AllTags from "components/widgets/AllTags"

const pjt = require("root/project.json")

const IndexPage = ({ data }) => (
  <Layout>
    <Head id="home" />
    <div className="alert is-info is-desktop-center">
      <h1 className="heading is-xs">{pjt.site.description}</h1>
    </div>
    <GridAside>
      <LastUpdated />
      <FirstPublished />
      <PickupTags />
      <AllTags />
    </GridAside>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    posts: allAirtable(
      limit: 10
      filter: { table: { eq: "posts" }, data: { published: { eq: true } } }
      sort: { fields: [data___updatedAt], order: DESC }
    ) {
      edges {
        node {
          data {
            title
            published
            slug
            publishedAt
            updatedAt
            status
            tags {
              data {
                name
                slug
                icon_style
                icon_name
                icon_color
              }
            }
          }
        }
      }
    }
    tags: allAirtable(
      filter: {
        table: { eq: "tags" }
        data: { published: { eq: true }, pickup: { eq: true } }
      }
    ) {
      edges {
        node {
          data {
            name
            published
            pickup
            slug
            icon_style
            icon_name
            icon_color
            content {
              childMarkdownRemark {
                excerpt(pruneLength: 50, truncate: true)
              }
            }
            posts {
              data {
                published
              }
            }
          }
        }
      }
    }
  }
`
