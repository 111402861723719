import React from "react"
import { Link } from "gatsby"
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

//import Mikan from "mikanjs"
//import { DateTime } from "luxon"

import TagIcon from "components/TagIcon"

class CardPickup extends React.Component {
  render() {
    return (
      <Link className="card-item is-angle-right" to={this.props.to}>
        <div className="inner is-padding-lg is-space-half">
          <div className="grid is-middle is-gap-2x">
            <div className="col">
              <TagIcon
                size="is-xl"
                icon_style={this.props.icon_style}
                icon_name={this.props.icon_name}
                icon_color={this.props.icon_color}
              />
            </div>
            <div className="col is-mobile-0 is-space-quarter">
              <h1 className="text is-strong is-dark-2 is-sm">
                {this.props.title}
              </h1>
              <p className="text is-dark-2 is-space-right-half is-xs">
                {this.props.content}
              </p>
            </div>
          </div>
        </div>
      </Link>
    )
  }
}

export default CardPickup
