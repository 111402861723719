import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import CardPost from "components/CardPost"

const FirstPublished = () => (
  <StaticQuery
    query={firstPublishedQuery}
    render={data => {
      return (
        <div className="card is-separate is-overflow-hidden">
          <div className="card-header">
            <div className="inner">
              <h2 className="heading is-dark-2 is-strong is-sm">
                First Published
              </h2>
            </div>
          </div>
          <ul className="card-list is-separate">
            {data.posts.edges.map((edge, i) => (
              <li key={i}>
                <CardPost
                  to={`/posts/${edge.node.data.slug}/`}
                  title={edge.node.data.title}
                  icon_style={edge.node.data.tags[0].data.icon_style}
                  icon_name={edge.node.data.tags[0].data.icon_name}
                  icon_color={edge.node.data.tags[0].data.icon_color}
                  status={edge.node.data.status}
                  publishedAt={edge.node.data.publishedAt}
                  updatedAt={edge.node.data.updatedAt}
                />
              </li>
            ))}
            <li>
              <Link to={"/published/"} className="card-item">
                <div className="inner is-padding-xs">
                  <p className="text is-center is-sm">
                    <span className="text">もっと見る</span>
                    <span class="icon is-dark-6 is-sm">
                      <FontAwesomeIcon icon={["fas", "arrow-down"]} />
                    </span>
                  </p>
                </div>
              </Link>
            </li>
          </ul>
        </div>
      )
    }}
  />
)

export default FirstPublished

const firstPublishedQuery = graphql`
  query FirstPublishedQuery {
    posts: allAirtable(
      limit: 5
      filter: { table: { eq: "posts" }, data: { published: { eq: true } } }
      sort: { fields: [data___publishedAt], order: DESC }
    ) {
      edges {
        node {
          data {
            title
            published
            slug
            publishedAt
            updatedAt
            status
            tags {
              data {
                name
                slug
                icon_style
                icon_name
                icon_color
              }
            }
          }
        }
      }
    }
  }
`
